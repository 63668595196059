import styled, { css } from 'styled-components';
import { color, font, offset, mediaBreakpointUp, adaptiveHardValues, mediaBreakpointDown } from '@/style/mixins';
import { Colors } from '@/style/colors';

export const Container = styled.div<{ borderColored?: boolean; image?: boolean; bgColor?: Colors }>`
    display: flex;
    justify-content: space-between;
    ${offset('m', 'margin-bottom')};

    ${({ image, bgColor }) =>
        image
            ? css`
                  background-color: transparent;
              `
            : bgColor
            ? css`
                  background-color: ${color(bgColor)};
              `
            : css`
                  background-color: ${color('white')};
              `};
`;

export const TextWrapper = styled.div<{ onMaxWidth?: boolean }>`
    margin-top: 36px;

    ${({ onMaxWidth }) =>
        onMaxWidth &&
        css`
            ${adaptiveHardValues('max-width', 528, 544, null)};

            ${mediaBreakpointDown('md')} {
                width: 100%;
            }
        `}

    ${mediaBreakpointUp('fhd')} {
        margin-top: 48px;
    }

    ${mediaBreakpointUp('md')} {
        margin-top: 40px;
    }
`;

export const Title = styled.h2`
    ${font('h2')};
`;

export const Description = styled.p`
    margin-top: 12px;
    ${font('text2-sb')};
    ${adaptiveHardValues('max-width', 528, 544, null)}
`;

export const CloseBtn = styled.button<{ image?: boolean }>`
    position: absolute;
    z-index: 1;
    ${offset('l', 'top')};
    ${offset('l', 'right')};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 16px;
    height: 16px;
    background-color: transparent;
    cursor: pointer;
    border: none;

    svg {
        width: 16px;
        height: 16px;
        ${({ image }) =>
            image
                ? css`
                      stroke: ${color('white')};
                  `
                : css`
                      stroke: ${color('DeepBlue/900')};
                  `};
        pointer-events: none;
    }
`;
