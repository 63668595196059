import styled, { css } from 'styled-components';
import { adaptiveHardValues, color, mediaBreakpointDown, mediaBreakpointUp, offset, scrollbarImg } from '@/style/mixins';
import Modal from '@/components/common/Modal/Modal';
import { Colors } from '@/style/colors';
import { Container as ServicesForm } from '@/components/ui/BaseFormContentLeft/BaseFormContentLeft.styled';
import { Container as Image } from '@/components/common/Image/Image.styled';
import Picture from '../Picture/Picture';

export const ModalComponent = styled(Modal)<{ image?: boolean }>`
    margin: auto;
    ${({ image }) =>
        image
            ? css`
                  margin-right: auto;
              `
            : ''}
`;

export const ModalInner = styled.div<{ image?: boolean }>`
    flex-shrink: 1;
    overflow: auto;
    overflow-x: hidden;

    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
    }

    ${({ image }) =>
        image
            ? css`
                  ${scrollbarImg};
                  height: 70vh;
                  background: transparent;

                  img {
                      object-fit: contain;
                      display: flex;
                      margin: auto;
                  }

                  ${mediaBreakpointUp('xl')} {
                      height: 80vh;
                  }
              `
            : css`
                  height: auto;
              `};
`;

export const Container = styled.div<{
    bgColor?: Colors;
    image?: boolean;
    isWide?: boolean;
    imageRight?: boolean;
    notBottomOffset?: boolean;
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    ${({ image }) => (image ? 'width: 90vw;' : 'width: auto;')};
    height: auto;
    background-color: ${({ bgColor, image }) => (image ? 'transparent' : bgColor ? color(bgColor) : color('white'))};
    ${offset('l', 'padding')};

    ${mediaBreakpointUp('md')} {
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 40px);
    }

    ${mediaBreakpointUp('xl')} {
        ${({ image, isWide }) => (image ? 'width: 40vw;' : `width: ${isWide ? '1350px' : 'auto'};`)};
    }

    ${mediaBreakpointUp('fhd')} {
        max-height: 900px;
    }

    ${mediaBreakpointDown('md')} {
        height: calc(var(--vh, 1vh) * 100);
        max-height: 100dvh;
        width: 100%;
    }

    ${({ notBottomOffset }) =>
        notBottomOffset &&
        css`
            padding-bottom: 0;

            ${mediaBreakpointUp('md')} {
                padding-bottom: 0;
            }

            ${mediaBreakpointUp('xl')} {
                padding-bottom: 0;
            }
        `}

    ${ServicesForm} {
        position: initial;
    }

    ${Image} {
        width: 100%;
        height: 100%;
        background: transparent;
        margin-right: 0 !important;
        img {
            border-radius: 0;
            object-fit: contain !important;
            background: transparent;
        }
    }

    ${mediaBreakpointUp('xxl')} {
        ${({ image }) => (image ? 'width: 46vw;' : 'width: auto;')};
    }

    ${mediaBreakpointUp('fhd')} {
        ${({ image, isWide }) => (image ? 'width: 40vw;' : `width: ${isWide ? '1200px' : 'auto'};`)};
    }

    ${ModalInner} {
        position: relative;
    }

    ${({ imageRight }) =>
        imageRight &&
        css`
            display: flex;
            flex-direction: row;

            ${mediaBreakpointDown('xl')} {
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        `};
`;

export const ModalImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
`;

export const ImagePic = styled(Picture)`
    ${adaptiveHardValues('height', 306, null, 258)};
    ${adaptiveHardValues('width', 396, null, 335)};
`;

export const ModalWrapperHeaderInner = styled.div`
    ${adaptiveHardValues('width', 528, 544, null)};

    ${mediaBreakpointDown('md')} {
        width: 100%;
    }
`;
