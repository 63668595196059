import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { Container, ImagePic, ModalComponent, ModalImage, ModalInner, ModalWrapperHeaderInner } from './ModalWrapper.styled';
import { RootState } from '@/redux/rootReducer';
import { setModal } from '@/redux/modal/modalSlice';
import { connect, ConnectedProps } from 'react-redux';
import ModalHeader, { ModalHeaderProps } from '@/components/common/ModalHeader/ModalHeader';
import { Colors } from '@/style/colors';
import { useRouter } from 'next/router';
import { PictureType } from '../Picture/Picture';

const mapStateToProps = ({ modal }: RootState) => ({
    modal
});
const mapDispatchToProps = { setModal };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export interface ModalWrapperProps {
    id: string;
    title?: ModalHeaderProps['title'];
    description?: ModalHeaderProps['description'];
    bgColor?: Colors;
    borderHeaderColored?: boolean;
    image?: boolean;
    isWide?: boolean;
    imageRight?: PictureType;
    onMaxWidth?: boolean;
    notBottomOffset?: boolean;
}

const ModalWrapper: React.FC<ModalWrapperProps & PropsFromRedux> = ({
    id,
    title,
    description,
    setModal,
    modal,
    bgColor,
    borderHeaderColored,
    children,
    image,
    isWide = false,
    imageRight,
    onMaxWidth,
    notBottomOffset = false
}) => {
    const { asPath } = useRouter();
    const pathname = asPath.split('?')[0];

    const closeModal = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            setModal({ open: false, id: id, fromFooter: false });
        },
        [setModal, id]
    );

    const onRequestClose = useCallback(() => {
        setModal({ open: false, id: id, fromFooter: false });
    }, [setModal, id]);

    useEffect(() => {
        onRequestClose();
    }, [pathname]);

    return (
        <ModalComponent isOpen={modal.id === id && modal.open} onRequestClose={onRequestClose} image={image}>
            <Container bgColor={bgColor} image={image} isWide={isWide} imageRight={imageRight && true} notBottomOffset={notBottomOffset}>
                {imageRight ? (
                    <>
                        <ModalWrapperHeaderInner>
                            <ModalHeader
                                image={image}
                                onCloseClick={closeModal}
                                title={title}
                                description={description}
                                borderColored={borderHeaderColored}
                                bgColor={bgColor}
                                onMaxWidth={onMaxWidth}
                            />
                            <ModalInner image={image}>{children}</ModalInner>
                        </ModalWrapperHeaderInner>
                        <ModalImage>
                            {' '}
                            <ImagePic {...imageRight} alt="img" />
                        </ModalImage>
                    </>
                ) : (
                    <>
                        <ModalHeader
                            image={image}
                            onCloseClick={closeModal}
                            title={title}
                            description={description}
                            borderColored={borderHeaderColored}
                            bgColor={bgColor}
                            onMaxWidth={onMaxWidth}
                        />
                        <ModalInner image={image}>{children}</ModalInner>
                    </>
                )}
            </Container>
        </ModalComponent>
    );
};

export default connector(ModalWrapper);
