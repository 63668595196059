import React from 'react';
import { Bg, Container } from '@/components/ui/BaseFormCommon/BaseFormCommon.styled';
import { BaseFormCommonI, BaseUI } from '@/interfaces';
import { Colors } from '@/style/colors';

interface BaseFormCommonProps extends BaseFormCommonI, BaseUI {
    id?: string;
    visibleOverflow?: boolean;
    textColor?: Colors;
}

const BaseFormCommon = React.forwardRef<HTMLDivElement, BaseFormCommonProps>(
    (
        {
            picture,
            topOffset,
            bottomOffset,
            outsideTOffset,
            outsideBOffset,
            sideOffset = true,
            children,
            bgColor,
            className,
            id,
            visibleOverflow,
            textColor
        },
        ref
    ) => {
        return (
            <Container
                ref={ref}
                className={className}
                topOffset={topOffset}
                bottomOffset={bottomOffset}
                outsideTOffset={outsideTOffset}
                outsideBOffset={outsideBOffset}
                sideOffset={sideOffset}
                bgColor={bgColor}
                hasBg={!!picture}
                id={id}
                visibleOverflow={visibleOverflow}
                textColor={textColor}
            >
                {picture && <Bg {...picture} />}
                {children}
            </Container>
        );
    }
);

BaseFormCommon.displayName = 'BaseFormCommon';

export default BaseFormCommon;
