import styled, { css } from 'styled-components';
import { font, offset, mediaBreakpointUp, mediaBreakpointDown, color } from '@/style/mixins';
import { Container as CheckboxConfirm } from '@/components/ui/CheckboxConfirm/CheckboxConfirm.styled';
import { Container as InputFile } from '@/components/ui/InputFile/InputFile.styled';
import BaseFormCommon from '@/components/ui/BaseFormCommon/BaseFormCommon';
import { Bg, Bottom } from '../BaseFormCommon/BaseFormCommon.styled';
import { Container as ReCaptcha } from '@/components/ui/ReCaptcha/ReCaptcha.styled';

export const Container = styled(BaseFormCommon)`
    ${mediaBreakpointUp('xl')} {
        display: flex;
    }

    ${Bg} {
        top: auto;
        bottom: 0;
        left: 40px;
        width: 552px;
        height: 200px;

        ${mediaBreakpointDown('xl')} {
            display: none;
        }
    }
`;

export const ContentForm = styled.div`
    position: relative;

    ${mediaBreakpointUp('xl')} {
        width: calc(50% - 8px);
    }

    h2 {
        ${font('h1')};
    }

    & > p {
        ${font('text2-sb')};
        ${offset('m', 'margin-top')};
        color: ${color('text-tertiary')};

        ${mediaBreakpointUp('md')} {
            max-width: 440px;
        }
    }

    span {
        ${font('t1-body-sb')};
    }
`;

export const Form = styled.form<{ oneColumnOnMd?: boolean; bottomOffset?: boolean }>`
    position: relative;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    transition: height 0.3s ease;

    ${mediaBreakpointUp('md')} {
        margin-top: 32px;
    }

    ${mediaBreakpointUp('xl')} {
        width: calc(50% - 8px);
        margin-left: auto;
        margin-top: 0;
    }

    .itemForm {
        width: 100%;

        &:not(:first-child) {
            margin-top: 8px;
        }

        ${({ oneColumnOnMd }) =>
            !oneColumnOnMd &&
            css`
                ${mediaBreakpointUp('md')} {
                    width: calc(50% - 4px);

                    &:nth-child(odd) {
                        margin-right: 8px;
                    }

                    &:not(:nth-child(1)),
                    &:not(:nth-child(2)) {
                        margin-top: 8px;
                    }

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0;
                    }
                    &._textarea {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                ${mediaBreakpointUp('xl')} {
                    width: 100%;

                    &:nth-child(2) {
                        margin-top: 8px;
                    }

                    &:nth-child(odd) {
                        margin-right: 0;
                    }

                    & > p {
                        margin-top: 8px;
                    }
                }
            `}
    }

    ${Bottom} {
        width: 100%;
    }

    ${InputFile} {
        margin-top: 8px;
    }

    ${CheckboxConfirm} {
        ${offset('xl', 'margin-top')};
    }

    ${ReCaptcha} > div {
        ${mediaBreakpointDown('md')} {
            transform-origin: left;
            transform: scale(0.95);
        }
    }

    ${({ bottomOffset }) => bottomOffset && 'margin-bottom: 28px;'}
`;
