import styled from 'styled-components';
import ReactModal from 'react-modal';
import { mediaBreakpointDown } from '@/style/mixins';

export const Container = styled(ReactModal)`
    width: auto;
    height: auto;
    overflow: hidden;
    cursor: initial;
    outline: none;
    border-radius: 20px;

    ${mediaBreakpointDown('md')} {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }
`;
